import { LOGGED_IN, LOGGED_OUT, SET_AUTH, USERS_FETCHED,STATS_FETCHED } from "../types";

const INITIAL_STATE = {
    auth: false,
    user: null,
    token: null,
    users: [],
    stats:{}
}
export const authreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGGED_IN:
            return {
                ...state,
                auth: true,
                token: action.payload.token,
                user: action.payload.user
            }
            break;
        case SET_AUTH:
            return {
                ...state,
                auth: true,
                token: action.payload.token,
                user: action.payload.user
            }
            break;
        case LOGGED_OUT:
            return {
                ...state,
                auth: false,
                token: null,
                user: null
            }
            break;
        case USERS_FETCHED:
            return {
                ...state,
                users: action.payload
            }
            break;
        case STATS_FETCHED:
            return {
                ...state,
                stats:action.payload
            }
        default:
            return state;
            break;
    }
}