import { BUSINESSES_FETCHED } from "../types";

const INITIAL={
    businesses:[]
}

export const businessreducer=(state=INITIAL,action)=>{
    switch (action.type) {
        case BUSINESSES_FETCHED:
            return {...state,businesses:action.payload}
            break;
    
        default:
            return state;
            break;
    }
}