import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { login as loginAPI } from '../store/actions/authactions';
import { useHistory } from 'react-router-dom';
import client from '../store/client'
import { useDispatch } from 'react-redux';
import { SET_AUTH } from '../store/types';
import { Hidden } from '@material-ui/core'; 
import back from '../back.jpg';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://beattheq.ie/">
        BeatTheQ
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${back})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();
  const [username, setUsername] = useState(null)
  const [password, setPassword] = useState(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const login = (event) => {
    // event.preventDefault()
    if (!username) {
      toast('Email is required!', {
        type: 'error',
        autoClose: 5000
      })
      return;
    }
    if (!password) {
      toast('Password is required!', {
        type: 'error',
        autoClose: 5000
      })
      return
    }
    loginAPI(username, password)
      .then(data => {
        if (data.token) {
          toast('You are logged in', {
            type: 'success',
            autoClose: 5000
          })
          dispatch({
            type: SET_AUTH,
            payload: {
              token: data.token,
              user: data.userData
            }
          })
          client.defaults.headers.common["Authorization"] = data.token;
          history.push('/dashboard')
        }
        console.log(data)
      })
      .catch(err => {
        let message = 'Some Error Occured!'
        if (err.response && err.response.status) {
          if (err.response.status == 401)
            message = 'Invalid Credentials!'
          if (err.response.status == 404)
            message = 'User Not found!'
        }
        toast(message, {
          type: 'error',
          autoClose: 5000
        })
      })
  }
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Hidden xsDown>
        <Grid item xs={false} sm={4} md={7} className={classes.image}>
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in - BeatTheQ
          </Typography>
          <form className={classes.form} onSubmit={(e) => { e.preventDefault(); login(e) }} noValidate>
            <TextField
              onChange={(e) => setUsername(e.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}