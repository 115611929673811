import React from 'react'
import PeopleIcon from '@material-ui/icons/People';
import CalendarToday from '@material-ui/icons/CalendarToday';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import SettingsIcon from '@material-ui/icons/Settings';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
//import CreateUser from './views/CreateUser'
const CreateUser = React.lazy(() => import('./views/users/CreateUser'))
const EditUser = React.lazy(() => import('./views/users/EditUser'))
const Users = React.lazy(() => import('./views/users/Users'))
const Calender = React.lazy(() => import('./views/services/Calender'))
const Services = React.lazy(() => import('./views/services/Services'))
const EditService = React.lazy(() => import('./views/services/EditService'))
const CreateService = React.lazy(() => import('./views/services/CreateService'))
const Booking = React.lazy(() => import('./views/services/Bookings'))
const Settings = React.lazy(() => import('./views/business/Settings'))
const Profile = React.lazy(() => import('./views/profile/Profile'))
const Businesses = React.lazy(() => import('./views/businesses/Index'));
const CreateBusiness = React.lazy(() => import('./views/businesses/Create'));
const EditBusiness = React.lazy(() => import('./views/businesses/Edit'));
export default [
    { path: '/dashboard/admins', component: Users, name: 'Admins', sidebar: true, icon: <PeopleIcon /> },
    { path: '/dashboard/businesses', component: Businesses, name: 'Businesses', sidebar: true, icon: <BusinessCenterIcon /> },
    { path: '/dashboard/businesses/create', component: CreateBusiness, name: 'Create Business' },
    { path: '/dashboard/businesses/edit/:id', component: EditBusiness, name: 'Edit Business' },
    { path: '/dashboard/profile', component: Profile, name: 'Profile' },
    { path: '/dashboard/admins/create', component: CreateUser, name: 'Create Admin' },
    { path: '/dashboard/admins/edit/:id', component: EditUser, name: 'Edit Admin' },
    // {path:'/dashboard/settings',component:Settings,name:'Settings',sidebar:true,icon:<SettingsIcon />},
    // {path:'/dashboard/services',component:Services,name:'Services',sidebar:true,icon:<AddToQueueIcon />},
    // {path:'/dashboard/services/create',component:CreateService,name:'Services',sidebar:false},
    // {path:'/dashboard/services/edit/:id',component:EditService,name:'Edit Service'},
    // {path:'/dashboard/services/bookings/:id',component:Booking,name:'Edit Service'},
    // {path:'/dashboard/services/calender/:id',component:Calender,name:'Calender',icon:<CalendarToday />}
]