import axios from 'axios'
import store from './store'
import { createBrowserHistory } from 'history'
import { STOP_LOADING } from './types'
import { toast } from 'react-toastify'

const history = createBrowserHistory()
const client = axios.create({
 //baseURL:"http://localhost:3001"
 baseURL: 'https://m3vkgiup65.execute-api.eu-west-1.amazonaws.com/dev/',
})

client.interceptors.request.use(function (request) {

  request.headers.Authorization = localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')).token : null
  return request;
})
client.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (!error.response) {
    toast("Network Error!",{type:"error",autoClose:false})
    return Promise.reject(error)
  }
  if (error.response && (error.response.status == 401 || error.response.status == 400)) {
    console.log('not logged in')
    // localStorage.removeItem('session')
    store.dispatch({ type: STOP_LOADING })
    //window.location.href='/login';
  }
  if (error.response.status == 404) {
    store.dispatch({ type: STOP_LOADING })
  }
  if (error.response.status == 500) {
    console.log(error.response.data)
    let message = 'Internal Server Error';
    store.dispatch({ type: STOP_LOADING })
  }
  if (error.response.status == 501) {
    console.log(error.response.data)
    let message = error.response.data.error ? error.response.data : 'Internal Server Error';
    store.dispatch({ type: STOP_LOADING })
    store.dispatch({ type: 'notification', text: [message] })
  }
  if (error.response.status == 422) {
    store.dispatch({ type: STOP_LOADING })
    Object.keys(error.response.data.errors).forEach(element => {
      error.response.data.errors[element].forEach(errorString => {
        store.dispatch({ type: 'notification', text: [errorString] })
      })
    })
  }
  return Promise.reject(error);
})
export default client;