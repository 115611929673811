import { SERVICES_FETCHED } from "../types";

const INITIAL=[]

export const servicereducer=(state=INITIAL,action)=>{
    switch (action.type) {
        case SERVICES_FETCHED:
            return action.payload
            break;
    
        default:
            return state;
            break;
    }
}