export const apis= {
    login:"api/admin/login",
    getAllBusinesses:"api/admin/getAllBusinesses",
    getBusinessDetails:"api/business/detailsPublic/",
    createBusiness:"api/business/create",
    updateBusiness:"/api/admin/updateBusiness/",
    checkShortUrl:"api/admin/checkAvailibity/",
    fetchUsers:"api/admin/getAllAdmins",
    fetchSingleUser:"api/admin/getAdminDetails/",
    createUser:"api/admin/createUser",
    updateUser:"api/admin/updateAdmin/",
    deleteUser:"api/admin/deleteAdmin/",
    getStats:"api/admin/stats",
    ////
    createService:"/api/service/create",
    fetchSingleService:"/api/service/details/",
    updateService: "/api/service/update/",
    fetchServices:"/api/service/get",
    deleteService:"/api/service/delete/",
    getServiceBookings:"/api/booking/available/",
    fetchBusinessDetails:"/api/business/details",
    updateBusinessDetails:"/api/business/update",
    getBookingDetails:"/api/booking/getDetails/",
    addBooking:"/api/booking/create",
    cancelBooking:"/api/booking/cancel/",
    markComplete:"/api/booking/complete/",

    checkUser:"/api/user/check/"
}