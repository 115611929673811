import React from 'react'
import { Card, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
       maxWidth:"95vw",
       width:"100%",
       minHeight:"85vh",
       // padding:30,
       // display:"flex",
       // flexGrow:1
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});
export default function ContentCard(props) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            {props.children}
        </Card>
    )

}