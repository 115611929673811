import { createStore,combineReducers } from 'redux'

import { authreducer as auth } from './reducers/authreducer'
import { uireducer as ui } from './reducers/uireducer'
import { servicereducer as services } from './reducers/servicereducer'
import { businessreducer as business } from './reducers/businessreducer'
const reducer=combineReducers({
    auth,
    ui,
    services,
    business
});
const store=createStore(reducer);
export default store;